import { useAtom } from 'jotai';
import * as React from 'react';
import { subscriptionsAtom, subscriptionsSetAtom, userAtom } from '../../../state';
import styled from 'styled-components';
import { Error, ErrorLight } from '../../elements/typography';
import { API_URL } from '../../../mode';
import Loader from '../../elements/loader';
import { Navigate } from 'react-router-dom';
import Nav from '../../elements/nav';
import { Card } from '../../elements/sections';

const PointerLink = styled.a`
  cursor: pointer;
`;

const SubscribeButtons = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const SubscribeButton = styled.a`
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--brand-color);
  font-size: 1.2em;
  cursor: pointer;
  color: var(--brand-color);
`;

const CustomAmountContainer = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
`;
const CustomAmountInputContainer = styled.form`
  label {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    opacity: 0.8;
    margin-bottom: 4px;
  }
  input {
    display: block;
  }
`;
const CustomAmountInput = styled.input`
  flex-grow: 1;
`;
const CustomAmountInputWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
`;

const CustomAmountPrefix = styled.div`
  margin-right: 10px;
  font-size: 1.2em;
`;

const CustomAmountButton = styled.button`
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--brand-color);
  font-size: 1.2em;
  cursor: pointer;
  color: var(--brand-color);
  margin-left: 10px;
  margin-top: 0;

  &:hover,
  &:focus {
    border: 1px solid var(--brand-color);
  }
`;

const ErrorContainer = styled.div`
  margin-bottom: 20px;
`;

const Donations = () => {
  const [user, setUser] = useAtom(userAtom);
  const [subscriptions, setSubscriptions] = useAtom(subscriptionsAtom);
  const [subscriptionsSet, setSubscriptionsSet] = useAtom(subscriptionsSetAtom);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [unsubscribed, setUnsubscribed] = React.useState(false);

  const [customAmount, setCustomAmount] = React.useState(5);
  const [customOneOffAmount, setCustomOneOffAmount] = React.useState(5);

  const logout = React.useCallback(() => {
    setUser(undefined);
  }, []);

  React.useEffect(() => {
    if (!user || subscriptionsSet) {
      return;
    }

    setError('');
    setLoading(true);
    fetch(`${API_URL}/payment/subscriptions`, { headers: { Authorization: user.token } })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((subscriptions) => {
            setSubscriptions(subscriptions);
            setLoading(false);
            setSubscriptionsSet(true);
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(`Couldn't load your subscription. Try again later.`);
      });
  }, [user, subscriptionsSet]);

  const subscribeToPrice = async (price: number) => {
    setLoading(true);
    setUnsubscribed(false);

    const resp = await fetch(`${API_URL}/payment/subscriptions`, {
      method: 'POST',
      body: JSON.stringify({ price }),
      headers: { Authorization: user!.token, 'Content-Type': 'application/json' },
    });

    if (resp.status === 200) {
      const obj = await resp.json();
      window.location = obj.redirect;
    } else {
      setLoading(false);
      setError('An error occurred. Please try again later.');
    }
  };

  const subscribePreset = React.useCallback(
    async (evt: React.MouseEvent<HTMLAnchorElement>, price: number) => {
      evt.preventDefault();
      await subscribeToPrice(price);
    },
    [user]
  );

  const subscribe = React.useCallback(
    async (evt: React.FormEvent<HTMLFormElement>, price: number) => {
      evt.preventDefault();
      await subscribeToPrice(price);
    },
    [user]
  );

  const oneOff = React.useCallback(
    async (evt: React.FormEvent<HTMLFormElement>, price: number) => {
      evt.preventDefault();
      setLoading(true);

      const resp = await fetch(`${API_URL}/payment/subscriptions/single`, {
        method: 'POST',
        body: JSON.stringify({ price }),
        headers: { Authorization: user!.token, 'Content-Type': 'application/json' },
      });

      if (resp.status === 200) {
        const obj = await resp.json();
        window.location = obj.redirect;
      } else {
        setLoading(false);
        setError('An error occurred. Please try again later.');
      }
    },
    [user]
  );

  const cancel = React.useCallback(async () => {
    setLoading(true);

    const resp = await fetch(`${API_URL}/payment/subscriptions/${subscriptions[0].id}`, {
      method: 'DELETE',
      headers: { Authorization: user!.token },
    });

    setLoading(false);
    if (resp.status === 204) {
      setSubscriptions(subscriptions.slice(1));
      setUnsubscribed(true);
    } else {
      setError('An error occurred. Please try again later.');
    }
  }, [user, subscriptions]);

  const customAmountChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAmount((parseFloat(event.target.value) * 100) / 100);
  }, []);

  const customOneOffAmountChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomOneOffAmount((parseFloat(event.target.value) * 100) / 100);
  }, []);

  if (!user) {
    return <Navigate to="/membership/login" />;
  }

  return (
    <div>
      <Nav />
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorContainer>
          <Error>{error}</Error>
        </ErrorContainer>
      ) : (
        <>
          {unsubscribed && <p>You have successfully unsubscribed.</p>}
          <Card>
            {subscriptions.filter((sub) => sub.status === 'active').length === 0 ? (
              <>
                <p>You are not subscribed.</p>
                <SubscribeButtons>
                  <SubscribeButton onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => subscribePreset(evt, 200)}>$2/mo</SubscribeButton>
                  <SubscribeButton onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => subscribePreset(evt, 500)}>$5/mo</SubscribeButton>
                  <SubscribeButton onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => subscribePreset(evt, 1000)}>
                    $10/mo
                  </SubscribeButton>
                  <SubscribeButton onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => subscribePreset(evt, 1500)}>
                    $15/mo
                  </SubscribeButton>
                </SubscribeButtons>
                <CustomAmountContainer>
                  <CustomAmountInputContainer onSubmit={(evt: React.FormEvent<HTMLFormElement>) => subscribe(evt, customAmount * 100)}>
                    <label>Custom Amount</label>
                    <CustomAmountInputWrap>
                      <CustomAmountPrefix>$</CustomAmountPrefix>
                      <CustomAmountInput
                        type="text"
                        onChange={customAmountChange}
                        defaultValue={5}
                        autoComplete="transaction-amount"
                        inputMode="decimal"
                      />
                      <CustomAmountButton type="submit" disabled={!customAmount || customAmount < 2}>
                        Continue
                      </CustomAmountButton>
                    </CustomAmountInputWrap>
                  </CustomAmountInputContainer>
                  {(customAmount === 0 || customAmount < 2) && <ErrorLight>The minimum is $2.</ErrorLight>}
                </CustomAmountContainer>
              </>
            ) : (
              <>
                <p>You are subscribed at ${(subscriptions[0].price / 100).toFixed(2)}/month.</p>
                <p>Thank you so much!</p>
                <div>
                  <PointerLink className="brand-link" onClick={cancel}>
                    Click here to cancel.
                  </PointerLink>
                </div>
              </>
            )}
          </Card>
          <Card>
            <>
              <p>If you just want to make a one-time payment, you can do so here.</p>
              <CustomAmountContainer>
                <CustomAmountInputContainer onSubmit={(evt: React.FormEvent<HTMLFormElement>) => oneOff(evt, customOneOffAmount * 100)}>
                  <label>Custom Amount</label>
                  <CustomAmountInputWrap>
                    <CustomAmountPrefix>$</CustomAmountPrefix>
                    <CustomAmountInput
                      type="text"
                      onChange={customOneOffAmountChange}
                      defaultValue={5}
                      autoComplete="transaction-amount"
                      inputMode="decimal"
                    />
                    <CustomAmountButton type="submit" disabled={!customOneOffAmount || customOneOffAmount < 2}>
                      Continue
                    </CustomAmountButton>
                  </CustomAmountInputWrap>
                </CustomAmountInputContainer>
                {(customOneOffAmount === 0 || customOneOffAmount < 2) && <ErrorLight>The minimum is $2.</ErrorLight>}
              </CustomAmountContainer>
            </>
          </Card>
        </>
      )}
      <p>
        <PointerLink className="brand-link" onClick={logout}>
          Logout
        </PointerLink>{' '}
      </p>
    </div>
  );
};

export default Donations;
